import React from 'react'
import {ethers}  from "ethers";


// Dirección del contrato inteligente
const contractAddress = '0x02101dfB77FDE026414827Fdc604ddAF224F0921'; // Dirección válida del contrato

// ABI del contrato inteligente (interfaz del contrato)
const contractAbi = [{"inputs":[],"name":"InitializationFailed","type":"error"},{"anonymous":false,"inputs":[{"indexed":false,"internalType":"address","name":"account","type":"address"},{"indexed":false,"internalType":"address","name":"implementation","type":"address"},{"indexed":false,"internalType":"uint256","name":"chainId","type":"uint256"},{"indexed":false,"internalType":"address","name":"tokenContract","type":"address"},{"indexed":false,"internalType":"uint256","name":"tokenId","type":"uint256"},{"indexed":false,"internalType":"uint256","name":"salt","type":"uint256"}],"name":"AccountCreated","type":"event"},{"inputs":[{"internalType":"address","name":"implementation","type":"address"},{"internalType":"uint256","name":"chainId","type":"uint256"},{"internalType":"address","name":"tokenContract","type":"address"},{"internalType":"uint256","name":"tokenId","type":"uint256"},{"internalType":"uint256","name":"salt","type":"uint256"}],"name":"account","outputs":[{"internalType":"address","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address","name":"implementation","type":"address"},{"internalType":"uint256","name":"chainId","type":"uint256"},{"internalType":"address","name":"tokenContract","type":"address"},{"internalType":"uint256","name":"tokenId","type":"uint256"},{"internalType":"uint256","name":"salt","type":"uint256"},{"internalType":"bytes","name":"initData","type":"bytes"}],"name":"createAccount","outputs":[{"internalType":"address","name":"","type":"address"}],"stateMutability":"nonpayable","type":"function"}]; // Debes reemplazar esto con el ABI real del contrato

const Experimental = () => {
//contract address e implementation son los valores de proxy y address de contrato del deployado 6551.
//tokenContract es el address del contrato que creo el nft.
async function interactWithContract(chainId,tokenId,tokenContract,implementation) {//acordate address son strings
  
    try {
      // Solicitar al usuario que apruebe la conexión a Metamask
      await window.ethereum.enable();

      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts' // Solicitar permiso para acceder a las cuentas del usuario
      });

      // Crear una instancia de proveedor utilizando Metamask
      const provider = new ethers.providers.Web3Provider(window.ethereum);

      const signer = provider.getSigner();
  
      // Crear una instancia de contrato utilizando el proveedor y el ABI
      const contract = new ethers.Contract(contractAddress, contractAbi, signer);
  
      // Llamar al contrato
      const result = await contract.createAccount(implementation,chainId,tokenContract,tokenId,0,0x00, { gasLimit: 2000000, value: 0, from: accounts[0] });  
      console.log('Resultado de la interacción:', result);
    } catch (error) {
      console.error('Error al interactuar con el contrato:', error);
    }
  }

  
  
  return (
    <>
        <h1 style={{color: 'black'}}>Exp</h1>

        <button onClick={interactWithContract}>InteractWithContract</button>
    </>
  )
}

export default Experimental
