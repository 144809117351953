import "./App.css";
import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { ReactHiererchyChart } from "react-hierarchy-chart";
import moralis from "moralis";
import axios from "axios";
import { ethers, JsonRpcProvider } from "ethers";
import Logo from "./img/logo-principal.png";
import { Container, Col, Row } from "react-bootstrap";
import Tooltip from "@mui/material/Tooltip";
import { TiThMenu } from "react-icons/ti";
import { BiCopy } from "react-icons/bi";
import PacmanLoader from "react-spinners/PacmanLoader";
import { json, useParams } from "react-router-dom";
import Dropdown from "./components/Dropdown";
import { useNavigate } from "react-router-dom";
import WalletBase from "./img/walletBase.png";
import Checked from "./img/Checked.svg";

function App() {
  const navigate = useNavigate();

  const [tree, setTree] = useState(null);
  const [isVertical, setIsVertical] = useState(false);
  const { address, chainId } = useParams();
  const [inputAddress, setInputAddress] = useState(address);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [chainData, setChainData] = useState(null);
  const [selectedChain, setSelectedChain] = useState(chainId);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const walletRef = useRef(null);
  const [hasTba, setHasTba] = useState(null);
  const [hasRootMetadata, setHasRootMetadata] = useState();
  const [fullscreen, setFullscreen] = useState(false);
  const [messageCount200, setMessageCount200] = useState(0);

  const handleSelectChain = (selectedChain) => {
    setSelectedChain(selectedChain);
  };

  

  async function getChain() {
    const url =
      "https://27zbfe9ts0.execute-api.us-west-2.amazonaws.com/Test/chains";
    axios
      .get(url)
      .then((response) => {
        console.log('hasTBA',response.data.body)
        setChainData(response.data.body);
        
      })
      .catch((e) => {
        console.error(e);
      });
  }

  async function getRootMetadata() {
    const selectedChain = chainData.find((item) => item.chain === chainId);

    if (!selectedChain) {
      console.log("Chain not found.");
      return; // Exit early if the chain is not found
    }

    try {
      const url = `https://wzdptgqixi.execute-api.us-west-2.amazonaws.com/prod/getrootmetadata?rpc=${
        selectedChain.rpcUrl
      }&chainId=${selectedChain.chainId.toString()}&accountAddress=${address}`;

      const response = await axios.get(url);
      console.log(JSON.parse(response.data.metadata));
      setHasRootMetadata(JSON.parse(response.data.metadata));
    } catch (error) {
      console.error(error);
    }
  }

  async function walletHasTba(data) {
    const selectedChain = data.find((item) => item.chain === chainId);

    if (!selectedChain) {
      console.log("Chain not found.");
      return;
    }
    try {
      const url = `https://5v2a88nw63.execute-api.us-west-2.amazonaws.com/prod/hastba?wallet=${address}&rpc=${selectedChain.rpcUrl}`;

      const response = await axios.get(url);
      console.log("TIENE TBA",response.data, address);
      if (response.data == true) {
 
        setHasTba(true);
      }
      if (response.data == false) {

        setHasTba(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    startWebsocket(chainId, address);
    getChain();
  }, []);

  useEffect(() => {
    if (chainData !== null) {
      getRootMetadata();
    }
  }, [chainData]);

  useEffect(() => {
    if (chainData !== null) {
      walletHasTba(chainData);      
    }
  }, [chainData])
  

  useEffect(() => {
    if (!isLoading) {
      if (walletRef.current) {
        walletRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    }
  }, [isLoading, isVertical]);

  const handleError = (e) => {
    e.target.src =
      "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930"; // Aquí pon el url de tu imagen personalizada
  };

  const handleChange = (e) => {
    const input = e.target.value.trim();

    // Llamar a la función validarDireccion con el valor del input
    let patron = /^(0x)?[0-9a-fA-F]{40}$/;
    const isValidAddress = patron.test(input);
    setInputAddress(e.target.value);
    setButtonDisabled(!isValidAddress);
    // Si es válido, borrar el error y pasar el valor al callback setAddress
    if (isValidAddress) {
      setError("");
    } else {
      // Si no es válido, mostrar un mensaje de error y pasar una cadena vacía al callback setAddress
      setError("The address is not valid,  please check it and try again");
    }
  };

  function handleCopy(address) {
    navigator.clipboard.writeText(address);
    alert("copied");
  }

  const handleOnClick = () => {
    console.log("entra aca");
    navigate(`/search/${inputAddress}/${selectedChain}`);
    window.location.reload();
  };

  const handleViewData = (token_wallet) => {
    // Buscar la cadena seleccionada en chainData
    const selectedChainData = chainData.find(
      (chain) => chain.chain === selectedChain
    );

    if (selectedChainData) {
      // Si se encontró la cadena, imprimir su rpcUrl
      console.log("RPC URL:", selectedChainData.scanner);

      //matic mumbai
      const url = selectedChainData.scanner + "address/" + token_wallet;

      console.log("urLINK", url);
      window.open(url, "_blank");
    } else {
      // Si no se encontró la cadena, puedes mostrar un mensaje de error o hacer otra acción.
      console.log("Cadena no encontrada en chainData.");
    }
  };

  const startWebsocket = async (_chainId, _address) => {
    setIsLoading(true);
    
    try {
      const websocketEndpoint = `${process.env.REACT_APP_WSC}?walletRoot=${_address}&depth=0&chain=${_chainId}`;
      const websocket = new WebSocket(websocketEndpoint);
      let messageArray = [];
      let isEndReceived = false; // Flag to track if "END" message is received

      websocket.onopen = () => {
        console.log("Conexión establecida al WebSocket", websocket);
      };

      websocket.onmessage = async (event) => {
        const message = JSON.parse(event.data);

        if (message.statusCode === 200) {
          setMessageCount200((prevCount) => prevCount + 1);
        }
        if (message.statusCode === 201) {
          messageArray.push(message);
        } else if (message.statusCode === 202) {
          isEndReceived = true;
          let fullMessage = "";
          messageArray.forEach((msg) => {
            const buffer = Buffer.from(msg.message, "base64");
            const decodedChunk = buffer.toString("utf-8");
            fullMessage += decodedChunk;
          });
          try {
            const parsedData = JSON.parse(fullMessage);
            console.log("ARBOL:", parsedData);
            setTree([parsedData]);
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
          
          setIsLoading(false);
        }
      };

      websocket.onerror = (error) => {
        console.error("Error en la conexión WebSocket:", error);
        setIsLoading(false);
      };

      return () => {
        websocket.close();
      };
    } catch (error) {
      alert(error);
      setIsLoading(false);
    }
  };

  const toggleFullscreen = (imageSrc) => {
    console.log("image", imageSrc);
    if (imageSrc === null) {
      // Si imageSrc es undefined, asigna la URL de la imagen por defecto
      imageSrc =
        "https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930";
    }

    // Cambia el estado fullscreen a true y guarda la URL de la imagen seleccionada
    setFullscreen({ enabled: true, src: imageSrc });
  };

  // Dirección del contrato inteligente
  const contractAddress = '0x02101dfB77FDE026414827Fdc604ddAF224F0921'; // Dirección válida del contrato

  // ABI del contrato inteligente (interfaz del contrato)
  const contractAbi = [{"inputs":[],"name":"InitializationFailed","type":"error"},{"anonymous":false,"inputs":[{"indexed":false,"internalType":"address","name":"account","type":"address"},{"indexed":false,"internalType":"address","name":"implementation","type":"address"},{"indexed":false,"internalType":"uint256","name":"chainId","type":"uint256"},{"indexed":false,"internalType":"address","name":"tokenContract","type":"address"},{"indexed":false,"internalType":"uint256","name":"tokenId","type":"uint256"},{"indexed":false,"internalType":"uint256","name":"salt","type":"uint256"}],"name":"AccountCreated","type":"event"},{"inputs":[{"internalType":"address","name":"implementation","type":"address"},{"internalType":"uint256","name":"chainId","type":"uint256"},{"internalType":"address","name":"tokenContract","type":"address"},{"internalType":"uint256","name":"tokenId","type":"uint256"},{"internalType":"uint256","name":"salt","type":"uint256"}],"name":"account","outputs":[{"internalType":"address","name":"","type":"address"}],"stateMutability":"view","type":"function"},{"inputs":[{"internalType":"address","name":"implementation","type":"address"},{"internalType":"uint256","name":"chainId","type":"uint256"},{"internalType":"address","name":"tokenContract","type":"address"},{"internalType":"uint256","name":"tokenId","type":"uint256"},{"internalType":"uint256","name":"salt","type":"uint256"},{"internalType":"bytes","name":"initData","type":"bytes"}],"name":"createAccount","outputs":[{"internalType":"address","name":"","type":"address"}],"stateMutability":"nonpayable","type":"function"}]; // Debes reemplazar esto con el ABI real del contrato

  
  async function interactWithContract(tokenId,tokenContract,implementation) {//acordate address son strings
   
    

    const selectedChainData = chainData.find(
      (chain) => chain.chain === selectedChain
    );
    

      try {
        // Solicitar al usuario que apruebe la conexión a Metamask
        await window.ethereum.enable();
  
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts' // Solicitar permiso para acceder a las cuentas del usuario
        });
  
        // Crear una instancia de proveedor utilizando Metamask
        const provider = new ethers.providers.Web3Provider(window.ethereum);
  
        const signer = provider.getSigner();
    
        // Crear una instancia de contrato utilizando el proveedor y el ABI
        const contract = new ethers.Contract(contractAddress, contractAbi, signer);
    
        // Llamar al contrato
 
        const result = await contract.createAccount(implementation, selectedChainData.chainId, tokenContract, parseInt(tokenId), 0, 0x00, { gasLimit: 2000000, value: 0, from: accounts[0] });  
        console.log('Resultado de la interacción:', result);
      } catch (error) {
        console.error('Error al interactuar con el contrato:', error);
      }
    }

  return (
    <div className="app-container">
      <Container className="d-flex align-items-center justify-content-center">
        <Row className="navbar-search">
          <Col
            sm={12}
            md={3}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <a href="https://dappsfactory.io" target="_blank" rel="noreferrer">
              <img
                src={Logo}
                alt="dApps Factory"
                className="logo-nav d-none d-md-block"
              />
              <img
                src={Logo}
                alt="dApps Factory"
                className="logo-nav-mini d-sm-block d-md-none"
              />
            </a>
          </Col>
          <Col
            sm={7}
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Dropdown data={chainData} func={handleSelectChain} />
            <div className="search-bar-container">
              <input
                type="text"
                placeholder="Search address here"
                className="search-bar-nav"
                onChange={handleChange}
                disabled={isLoading}
              />
              <button
                className="search-btn"
                onClick={handleOnClick}
                disabled={buttonDisabled}
              >
                Search
              </button>
            </div>
            {error && <p style={{ color: "red" }}>{error}</p>}
            <label
              style={{
                color: "white",
                textAlign: "center",
                fontWeight: "400",
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
              className="d-none d-md-block"
            >
              <span style={{ fontWeight: "bold" }}>Wallet:</span> {address}{" "}
              <BiCopy
                style={{ cursor: "pointer" }}
                onClick={() => handleCopy(address)}
              />
            </label>

            <label
              style={{
                color: "white",
                textAlign: "center",
                fontWeight: "400",
                fontSize: "14px",
                marginTop: ".25rem",
                marginBottom: ".25rem",
              }}
              className="d-sm-block d-md-none"
            >
              <span style={{ fontWeight: "bold" }}>Wallet:</span> {address}
            </label>
          </Col>
          <Col sm={1} md={3}>
            <TiThMenu className="Menu" onClick={() => setIsVertical(false)} />
            <TiThMenu
              className="Menu-inverted"
              onClick={() => setIsVertical(true)}
            />
          </Col>
          <Col
            md={12}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          ></Col>
        </Row>
      </Container>
      {/* CARD TEST */}

      <div style={{ overflowX: "auto", width: "100%" }}>
        <Container></Container>
        <div className="hierarchy-viewer" style={{ marginLeft: "2rem" }}>
          {!isLoading && tree ? (
            <ReactHiererchyChart
              nodes={tree}
              lineColor="red"
              direction={isVertical ? "vertical" : "horizontal"}
              randerNode={(node) => {
                return (
                  <>
                    <div className={`contenedor`}>
                      <div className="contenedor_tarjeta">
                        <div className="remplazohref">
                          <figure style={{ height: "100%" }}>
                            {node.metadata || node.token_wallet ? (
                              <div className="frontal">
                                <div className="img-container">
                                  {node.metadata !== null &&
                                  node.token_owner ? (
                                    <img
                                      src={
                                        JSON.parse(
                                          node?.metadata
                                        )?.image?.slice(0, 12) ===
                                        "ipfs://ipfs/"
                                          ? "https://ipfs.io/ipfs/" +
                                            JSON.parse(
                                              node?.metadata
                                            )?.image?.slice(12)
                                          : JSON.parse(
                                              node?.metadata
                                            )?.image?.slice(0, 7) === "ipfs://"
                                          ? "https://ipfs.io/ipfs/" +
                                            JSON.parse(
                                              node?.metadata
                                            )?.image?.slice(7)
                                          : JSON.parse(node?.metadata)?.image
                                      }
                                      alt="card-user"
                                      style={{
                                        borderRadius: "15px",
                                      }}
                                      onError={handleError}
                                    />
                                  ) : (
                                    <>
                                      <img
                                        src={
                                          hasRootMetadata?.image.slice(
                                            0,
                                            12
                                          ) === "ipfs://ipfs/"
                                            ? "https://ipfs.io/ipfs/" +
                                              hasRootMetadata?.image.slice(12)
                                            : hasRootMetadata?.image.slice(
                                                0,
                                                7
                                              ) === "ipfs://"
                                            ? "https://ipfs.io/ipfs/" +
                                              hasRootMetadata?.image.slice(7)
                                            : WalletBase
                                        }
                                        alt=""
                                        ref={walletRef}
                                        id="wallet-inicio"
                                      />
                                      {/* <p style={{color: 'white'}}>inicial: {hasRootMetadata?.name} </p> */}
                                    </>
                                  )}
                                  <div className="title-frontal">
                                    {node.metadata && (
                                      <p style={{ color: "white" }}>
                                        {JSON.parse(node.metadata).name}
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <img
                                src="https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg?20200913095930"
                                alt=""
                              />
                            )}
                            <figcaption className="trasera">
                              <Container className="back-container">
                                <div className="back">
                                  <div style={{ textAlign: "right" }}>
                                    {node.hasTba === "true" && (
                                      <img
                                        src={Checked}
                                        alt=""
                                        width="20px"
                                        className="mx-1"
                                      />
                                    )}
                                  </div>
                                  {node.token_wallet && (
                                    <label className="label-back">
                                      Wallet Address:
                                      <span className="span-back">
                                        {node.token_wallet}
                                      </span>
                                      <Tooltip title="Copy">
                                        <span>
                                          <BiCopy
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              handleCopy(node.token_wallet)
                                            }
                                          />
                                        </span>
                                      </Tooltip>
                                    </label>
                                  )}
                                  {node.token_owner && (
                                    <label className="label-back">
                                      Owner Address :
                                      <span className="span-back">
                                        {node.token_owner}
                                      </span>
                                      <Tooltip title="Copy">
                                        <span>
                                          <BiCopy
                                            style={{ cursor: "pointer" }}
                                            onClick={() =>
                                              handleCopy(node.token_owner)
                                            }
                                          />
                                        </span>
                                      </Tooltip>
                                    </label>
                                  )}

                                  {node.token_id && (
                                    <label className="label-back">
                                      Token Id:
                                      <span className="span-back">
                                        {node.token_id}
                                      </span>
                                    </label>
                                  )}

                                  {node.amount && (
                                    <label className="label-back">
                                      Token Amount:
                                      <span className="span-back">
                                        {node.amount}
                                      </span>
                                    </label>
                                  )}

                                  <div
                                    style={{
                                      width: "100%",
                                      textAlign: "center",
                                      display: "flex",
                                    }}
                                  >
                                    <button
                                      className="view-btn w-1/2"
                                      onClick={() => {
                                        console.log(JSON.parse(node?.metadata));

                                        let imageUrl;

                                        if (node?.metadata) {
                                          const image = JSON.parse(
                                            node.metadata
                                          )?.image;

                                          imageUrl =
                                            image.slice(0, 12) ===
                                            "ipfs://ipfs/"
                                              ? "https://ipfs.io/ipfs/" +
                                                image.slice(12)
                                              : image.slice(0, 7) === "ipfs://"
                                              ? "https://ipfs.io/ipfs/" +
                                                image.slice(7)
                                              : image;
                                        } else {
                                          imageUrl = WalletBase;
                                        }

                                        toggleFullscreen(imageUrl);
                                      }}
                                    >
                                      View
                                    </button>
                                    <button
                                      className="view-btn w-1/2"
                                      onClick={() =>{
                                        {console.log(node)}
                                        interactWithContract(node.token_id,node.tokenContract,"0x2d25602551487c3f3354dd80d76d54383a243358")
                                      }}
                                    >
                                      Create TBA
                                    </button>
                                  </div>
                                </div>
                              </Container>
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </>
                );
              }}
            />
          ) : (
            <Container className="loader-container">
              <PacmanLoader
                size={50}
                aria-label="Loading Spinner"
                data-testid="loader"
                color="#ba74f2"
                className="loader"
              />
              {messageCount200 > 0 ? (
                <div className="mt-4 text-bold">
                  Loaded {messageCount200} 
                  {messageCount200 === 1 ? " object" : " objects"}. Please wait.
                </div>
              ) : (
                <div className="mt-4 text-bold">Loading...</div>
              )}
            </Container>
          )}
        </div>
      </div>
      {fullscreen.enabled && (
        <div
          style={{
            display: "flex",
            height: "100%",
            width: "100%",
            margin: "0",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            padding: "10px",
            zIndex: "1550",
            position: "absolute",
          }}
        >
          <div
            className="fullscreen-image-container"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <button
              style={{
                fontWeight: "bold",
                marginBottom: "4px",
                marginLeft: "3px",
                color: "black",
              }}
              onClick={() => setFullscreen({ enabled: false, src: "" })}
            >
              Cerrar
            </button>
            <img
              src={fullscreen.src}
              alt="incidente"
              style={{
                maxHeight: "80vh",
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default App;
