import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Table from 'react-bootstrap/Table';

const Logs = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Realizar la solicitud Axios a la API
    axios.get('https://27zbfe9ts0.execute-api.us-west-2.amazonaws.com/Test/logs')
      .then(response => {
        setLogs(response.data);
        setLoading(false);
        console.log(response.data); // Mostrar los datos en la consola
      })
      .catch(error => {
        console.error('Error al obtener los datos:', error);
        setLoading(false);
      });
  }, []);

  console.log(logs)

  return (
    <div>
      <Table striped bordered hover variant="dark" className='text-center'>
      <thead>
        <tr>
          <th>Chain</th>
          <th>TreeId Wallet</th>
          <th>URL</th>
        </tr>
      </thead>
      <tbody>
        {logs.body?.map((log, index) => (
          <tr key={index}>
            <td>{log.chain.S}</td>
            <td>{log.treeIdWallet.S}</td>
            <td>
              <a href={log.url.S} target="_blank" rel="noopener noreferrer">
                {log.url.S}
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    </div>
  );
}

export default Logs;